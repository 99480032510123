.button {
    position: relative;
    overflow: hidden;
    background: #0bad49;
    background-size: 400%;
    color: #fff;
    cursor: pointer;
    width: 120px;
    height: 45px;
  }
  .button:hover{
    border-radius: 10px;
  }
  
  .button:hover::before {
    transform: scaleX(1);
  }
  
  .button-content {
    position: relative;
    z-index: 1;
  }
  
  .button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    width: 100%;
    height: inherit;
    border-radius: inherit;
    background: #006427;
    transition: all 0.475s;
  }